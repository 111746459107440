<template>
  <div class="notifications-wrapper">
    <template v-for="(rec, index) in notifications">
      <TransactionNotification v-if="rec.show" :key="rec.hash" :rec="rec" @close="close(index)" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useMainStore } from '~/stores/mainStore';
import { flagAsSeenHistoryHash, getHashHistory } from '~/utils';
import type { HashRecord } from '~/utils';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';

const notifications = ref<HashRecord[]>([]);
const { isConnected } = useWeb3ModalAccount();

onMounted(() => {
  notifications.value = getHashHistory();
});

watch(isConnected, () => {
  if (!isConnected.value) notifications.value = [];
});

function close(index: number) {
  flagAsSeenHistoryHash(notifications.value[index].hash);
  const store = useMainStore();
  store.updateVersion();
}
</script>

<style scoped>
.notifications-wrapper {
  position: fixed;
  margin: 20px;
  bottom: 20px;
  right: 0;
  z-index: 100;
  max-height: 520px;
  overflow: hidden;
}

@media screen and (max-width: 479px) {
  .notifications-wrapper {
    margin: 8px;
    width: calc(100% - 16px);
  }
}
</style>

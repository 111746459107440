<template>
  <div class="app-wrapper">
    <!-- <NuxtErrorBoundary @error="someErrorLogger"> -->
    <AppHeader />

    <div class="page">
      <div v-if="$route.name !== 'referral'" class="scene-wrapper">
        <div class="scene">
          <history-modal v-if="$route.name === 'story'" @run-animation="runAnimation" />

          <TiledScene
            v-for="(tilemap, i) in tilemaps"
            :key="i"
            v-loading="loading"
            :animation="animation"
            :tilemap="tilemap"
            @reset-animation="resetAnimation"
          />
        </div>
        <MapScrollIndicator v-if="!loading && tilemaps.length" />
      </div>
      <div class="main-body-container">
        <slot />
      </div>
      <div class="mobile-footer">
        <AppFooter />
      </div>
      <div class="footer">
        <AppFooter />
      </div>
    </div>

    <el-dialog v-model="showPopupValue" title="Error" :append-to-body="true" @closed="close">
      <div class="error-wrapper">
        {{ error }}
      </div>
      <div class="btns">
        <button class="btn-primary" @click="close">close</button>
      </div>
    </el-dialog>
    <RegisterUserModal v-model="isShowReferralPopup"></RegisterUserModal>

    <TransactionNotifications :key="notificationUpdateVersion" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type TiledMap from 'tiled-tmj-typedefs/types/TiledMap';

import { useMainStore } from '~/stores/mainStore';
import { mapState, mapActions } from 'pinia';
import { updateProcessingTransactions } from '~/utils';
import useEnvs from '~/composables/useEnvs';

export default defineComponent({
  provide() {
    return {
      joinReferralProgram: () => {
        this.isShowReferralPopup = true;
      }
    };
  },
  async setup() {
    const { blockchain, apiUrl } = useEnvs();

    return { blockchain, apiUrl };
  },
  data(): {
    isShowReferralPopup: boolean;
    loading: boolean;
    tilemaps: TiledMap[];
    animation: string;
  } {
    return {
      isShowReferralPopup: false,
      loading: false,
      tilemaps: [],
      animation: ''
    };
  },
  computed: {
    ...mapState(useMainStore, ['error', 'notificationUpdateVersion']),
    showPopupValue(): boolean {
      return !!this.error;
    }
  },
  async mounted() {
    if (document) {
      document.getElementsByTagName('html')[0].classList.add('dark');
    }
    this.loading = true;
    this.tilemaps = await Promise.all([fetch('/assets/new_scenes/Map3.tmj').then((rs) => rs.json())]);
    this.loading = false;

    updateProcessingTransactions(); // show all processing transaction
    this.updateVersion();
  },
  methods: {
    ...mapActions(useMainStore, ['setError', 'updateVersion', 'setUserData', 'setUserSubscribersData']),
    close() {
      this.setError('');
    },
    runAnimation(name: string) {
      this.animation = name;
    },
    resetAnimation() {
      this.animation = '';
    }
  }
});
</script>

<style scoped lang="scss">
#content {
  position: absolute;
  right: 0;
  left: 0;
}

.mobile-footer {
  display: none;
}

@media screen and (max-width: 1200px) {
  .mobile-footer {
    display: block;
  }

  .footer {
    display: none;
  }
}

.page {
  background-color: var(--blue-1);
  background-image: linear-gradient(rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.1));
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 1px);
}

.error-wrapper {
  margin-bottom: 12px;
  text-align: center;
}
</style>

<style lang="scss">
.el-dialog.login-popup {
  width: 280px;
}

.el-dialog.video-popup {
  width: 100%;
  max-width: 720px;

  .el-dialog__body {
    max-width: 700px;
  }
}

.el-dialog.full-body {
  width: 100%;
  max-width: 720px;

  .el-dialog__body {
    max-width: 100%;
  }
}
.el-dialog.buy-popup {
  max-width: 350px;

  .show-close {
    padding-right: 0;
  }
}

.el-dialog.info {
  max-width: 600px;
}

.el-dialog.is-fullscreen {
  width: 100%;
  max-width: 100%;
}

.el-dialog {
  border-radius: 12px;
  // border: 2px solid var(--main-blue);
  backdrop-filter: blur(5px);
  background-color: rgba(2, 27, 38, 0.8);
  overflow: hidden;
  max-width: 574px;
  box-shadow: 0 0 11px 5px rgba(0, 0, 0, 0.4);
  border: 1px solid #17353f;

  &__header {
    text-align: center;
    margin: 0;
    padding: 0 0 0 0;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;

    font-family: Grenze, sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 1.273em;
  }

  .el-dialog__title {
    font-size: 24px;
  }

  &__body {
    max-width: 415px;
    margin: auto;
    padding-bottom: 20px;
    padding-top: 12px;
  }

  &__close,
  .el-icon svg {
    height: 30px;
    width: 30px;
  }
}

.popup-canceled {
  border: 2px solid var(--canceled-color);
}

.popup-confirm {
  border: 2px solid var(--confirm-color);
}

.el-button {
  display: flex;
  padding: 0 38px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 86px;
  background: var(--main-blue);
  text-align: center;
  font-size: 24px;
  color: var(--main-text-color);
  border: 1px solid transparent;
  height: 47px;

  &:active,
  &:focus,
  &:hover {
    background: transparent;
    border: 1px solid #939393;
  }
}

.table .el-table {
  --el-table-border-color: none;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.55);
  &:not(.line-rounded-icon, .line-square-icon) {
    font-family: Grenze, sans-serif;
  }

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
}

.table .el-table tr {
  background-color: transparent;
}

.table .el-table tr:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.3);
}

.table .el-table th.el-table__cell {
  background: var(--dark-2);
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
  margin-bottom: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: transparent;
  color: #fff;
  font-family: Grenze, sans-serif;
  font-size: 24px;
  font-weight: 400;

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 479px) {
    font-size: 12px;
  }
}

.el-table .el-table__cell {
  @media screen and (max-width: 991px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 479px) {
    font-size: 12px;
  }
}

.el-table .cell {
  word-break: normal;
}

.el-table th.el-table__cell:first-child {
  border-radius: 16px 0 0 0;
}

.el-table th.el-table__cell:last-child {
  border-radius: 0 16px 0 0;
}

.el-icon.el-dialog__close svg {
  display: none;
}

.el-icon.el-dialog__close {
  background-image: url('~/public/img/icons/bone_close.svg');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;

  &:hover {
    opacity: 0.6;
  }
}

.btns {
  display: flex;
  justify-content: center;
}

.el-button + .el-button {
  margin-left: 0 !important;
}

.el-checkbox__label {
  color: var(--second-text-color);
  text-align: left;
  font-family: Grenze, sans-serif;
  white-space: initial;
  line-height: 1.333em;
  font-size: 16px !important;

  @media screen and (max-width: 767px) {
    font-size: 14px !important;
  }
}

.el-checkbox {
  display: flex;
  align-items: flex-start;
  height: auto !important;
}

.el-checkbox__input {
  margin-top: 4px;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: var(--main-text-color);
}

.scene-wrapper {
  width: 100%;
  height: calc(100dvh - 117px);
}
</style>

<style lang="scss">
.modalCls {
  text-align: center;
}
</style>

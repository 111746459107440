<template>
  <div>
    <el-dialog
      v-if="isShowReferralPopup"
      v-model="isShowReferralPopup"
      title="Welcome to Referral program!"
      :append-to-body="true"
      modal-class="modalCls"
      :width="$device.isMobile ? '80%' : '50%'"
      @closed="closeReferral"
    >
      <p>Now you have the unique extra income opportunity!</p>
      <p>
        Get your personal link, share it with your friends and get USDT bonuses from each purchase. It's easy, isn`t it?
        <br />
        <span>{{ $route.query?.ref && `Your referrer's id: ${$route.query?.ref}` }}</span>
        <br />
        <span>Join us and turn your recommendations to incomes.</span>
      </p>

      <div class="btns">
        <button
          :loading="joinLoading"
          class="btn-primary"
          size="large"
          :disabled="joinLoading"
          @click="registerUserWithReferral(address, Number($route.query?.ref) || 0)"
        >
          <span>
            {{ !address ? 'Use wallet to participate' : 'Participate' }}
            <span
              class="line-rounded-icon link-icon-right"
              style="
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                  skew(0deg);
                transform-style: preserve-3d;
              "
              ></span
            >
          </span>
        </button>
      </div>
    </el-dialog>
    <el-dialog
      v-if="isShowReferrerError"
      v-model="isShowReferrerError"
      title="Error in Referrer Id!"
      :append-to-body="true"
      modal-class="modalCls"
      :width="$device.isMobile ? '80%' : '50%'"
    >
      <p>
        We're sorry, but the referrer ID
        <span>{{ $route.query?.ref && `"${$route.query?.ref}" ` }}</span>
        in the referral link isn’t valid in our system. Please ensure that you are using the correct referral link and,
        if necessary, contact our support team for further assistance. Thank you!
      </p>
    </el-dialog>
    <el-dialog
      v-if="showCancelPopup"
      v-model="showCancelPopup"
      class="popup-canceled buy-popup"
      :fullscreen="$device.isMobile"
      title="Canceled"
      :append-to-body="true"
    >
      <p>{{ errorMessage }}</p>

      <div class="btns-wrapper">
        <button class="btn-primary" size="large" @click="showCancelPopup = false">OK</button>
      </div>
    </el-dialog>
  </div>
</template>
<script setup lang="ts">
import { useRoute, useRouter } from '#app';
import { ElNotification, stringifyError, useDevice, useMainStore } from '#imports';
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/vue';
import { onMounted, ref } from 'vue';
import PearlApiService from '~/composables/PearlApiService';
import type { TNullable } from '~/types/common';
import useEnvs from '~/composables/useEnvs';
const { apiUrl } = useEnvs();
const { address } = useWeb3ModalAccount();
const { walletProvider } = useWeb3ModalProvider();
const store = useMainStore();
const { open } = useWeb3Modal();

const route = useRoute();
const router = useRouter();
const { isMobile } = useDevice();
const isShowReferralPopup = defineModel<boolean>({ default: false });
const isShowReferrerError = ref(false);
const showCancelPopup = ref(false);
const errorMessage = ref('');
const joinLoading = ref(false);

// const closeCanceled = () => {
//   showCancelPopup.value = false;
// }
const closeReferral = () => {
  isShowReferralPopup.value = false;
  if (route.path.includes('referral')) {
    router.back();
  }
  router.replace({
    query: {
      ...route.query,
      ref: undefined
    }
  });
};

const checkReferrerExists = async (ref?: number) => {
  if (ref === undefined || isNaN(ref)) {
    return false;
  }
  const getUserExists = await PearlApiService.getUserById(apiUrl, ref as number);
  return getUserExists?.exists === true;
};

const registerUserWithReferral = async (address?: TNullable<string>, ref?: number) => {
  // address or ref is undefined
  if (!isMobile && typeof ref !== 'number') {
    return;
  }
  // connect metamask first
  if (!address) {
    // if no address were found, trigger wallet logic;
    open();
    return;
  }

  // user already registered
  const getUserResp = await PearlApiService.getUser(apiUrl, address);
  if (getUserResp?.user?.id) {
    ElNotification.info({
      title: '',
      message: 'You have already joined partners program.'
    });
    isShowReferralPopup.value = false;
    return;
  }
  joinLoading.value = true;
  try {
    if (address && walletProvider.value) {
      const message = `referrer:${ref}:timestamp:${Date.now()}`;
      const signature = await walletProvider.value?.request({
        method: 'personal_sign',
        params: [message, address]
      });
      let signatureStr: string = '';
      if (typeof signature === 'string') {
        signatureStr = signature;
      }
      const signRes = await PearlApiService.registerUserWithReferral(apiUrl, {
        address,
        message,
        signature: signatureStr
      });
      if (signRes) {
        const [userData, userSubscribersData] = await Promise.all([
          PearlApiService.getUser(apiUrl, address),
          PearlApiService.getUserSubscribers(apiUrl, address)
        ]);

        store.setUserData(userData);
        store.setUserSubscribersData(userSubscribersData);
        joinLoading.value = false;
        isShowReferralPopup.value = false;
      }
    }
  } catch (err) {
    showCancelPopup.value = true;
    joinLoading.value = false;
    errorMessage.value = stringifyError(err);
  }
};

onMounted(() => {
  if (route.query?.ref) {
    checkReferrerExists(Number(route.query?.ref)).then((isExists) => {
      if (isExists) {
        isShowReferralPopup.value = true;
        return;
      }
      isShowReferralPopup.value = false;
      isShowReferrerError.value = true;
    });
  }
});
</script>
<style lang="scss" scoped></style>
